import PropTypes from 'prop-types';
import React from 'react';

import { captionStyles, col, imageStyle, root } from './index.module.scss';
import buildImageObj from '../../utils/build-image-obj';
import imageUrlFor from '../../utils/image-url-for';

const OneImage = (props) => {
  const { image } = props;

  return (
    <section className={root}>
      <div className={col}>
        {image && image.asset && (
          <img
            className={imageStyle}
            src={imageUrlFor(buildImageObj(image)).width(1365).height(966).fit('crop').auto('format').quality(80).url()}
            alt={image.alt}
          />
        )}
        {image && image.caption && <div className={captionStyles}>{image.caption}</div>}
      </div>
    </section>
  );
};

OneImage.propTypes = {
  image: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({}),
    caption: PropTypes.string,
  }).isRequired,
};

export default OneImage;
