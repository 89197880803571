import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import PropTypes from 'prop-types';
import React from 'react';

import { root } from './index.module.scss';
import clientConfig from '../../../client-config';

const Figure = ({ node }) => {
  if (!node.asset) {
    return null;
  }

  const gatsbyImageData = getGatsbyImageData(node, { maxWidth: 675 }, clientConfig.sanity);
  return <GatsbyImage className={root} image={gatsbyImageData} />;
};

Figure.propTypes = {
  node: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({
      _ref: PropTypes.shape({}),
    }),
  }).isRequired,
};

export default Figure;
