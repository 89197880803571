import PropTypes from 'prop-types';
import React from 'react';

import { col, imageStyle, root } from './index.module.scss';
import BlockContent from '../block-content';
import buildImageObj from '../../utils/build-image-obj';
import imageUrlFor from '../../utils/image-url-for';

const Image = ({ image }) =>
  image &&
  image.asset && (
    <img
      className={imageStyle}
      src={imageUrlFor(buildImageObj(image)).width(431).height(298).fit('crop').auto('format').quality(80).url()}
      alt={image.alt}
    />
  );

Image.propTypes = {
  image: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({}),
  }).isRequired,
};

const ThreeArticle = (props) => {
  const { titleOne, copyOne, imageOne, titleTwo, copyTwo, imageTwo, titleThree, copyThree, imageThree } = props;

  return (
    <section className={root}>
      <div className={col}>
        <div>
          <h2>
            <span>{titleOne}</span>
          </h2>
          {copyOne && <BlockContent blocks={copyOne} />}
        </div>
        <Image image={imageOne} />
      </div>
      <div className={col}>
        <div>
          <h2>
            <span>{titleTwo}</span>
          </h2>
          {copyTwo && <BlockContent blocks={copyTwo} />}
        </div>
        <Image image={imageTwo} />
      </div>
      <div className={col}>
        <div>
          <h2>
            <span>{titleThree}</span>
          </h2>
          {copyThree && <BlockContent blocks={copyThree} />}
        </div>
        <Image image={imageThree} />
      </div>
    </section>
  );
};

ThreeArticle.propTypes = {
  copyOne: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  copyThree: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  copyTwo: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  imageOne: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({}),
  }).isRequired,
  imageThree: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({}),
  }).isRequired,
  imageTwo: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({}),
  }).isRequired,
  titleOne: PropTypes.string.isRequired,
  titleThree: PropTypes.string.isRequired,
  titleTwo: PropTypes.string.isRequired,
};

export default ThreeArticle;
