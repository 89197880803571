import React from 'react';

import { col, root } from './index.module.scss';
import GatsbyLink from '../gatsby-link';

const FooterNavigation = () => (
  <section className={root}>
    <div className={col}>
      <GatsbyLink to="#top">Back to top</GatsbyLink>
    </div>
  </section>
);

export default FooterNavigation;
