import PropTypes from 'prop-types';
import React from 'react';

import { captionStyles, col, imageStyle, root } from './index.module.scss';
import buildImageObj from '../../utils/build-image-obj';
import imageUrlFor from '../../utils/image-url-for';

const Image = ({ image }) =>
  image &&
  image.asset && (
    <img
      className={imageStyle}
      src={imageUrlFor(buildImageObj(image)).width(664).height(632).fit('crop').auto('format').quality(80).url()}
      alt={image.alt}
    />
  );

Image.propTypes = {
  image: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({}),
  }).isRequired,
};

const TwoImage = (props) => {
  const { imageOne, imageTwo } = props;

  return (
    <section className={root}>
      <div className={col}>
        <Image image={imageOne} />
        {imageOne && imageOne.caption && <div className={captionStyles}>{imageOne.caption}</div>}
      </div>
      <div className={col}>
        <Image image={imageTwo} />
        {imageTwo && imageTwo.caption && <div className={captionStyles}>{imageTwo.caption}</div>}
      </div>
    </section>
  );
};

TwoImage.propTypes = {
  imageOne: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({}),
    caption: PropTypes.string,
  }).isRequired,
  imageTwo: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({}),
    caption: PropTypes.string,
  }).isRequired,
};

export default TwoImage;
