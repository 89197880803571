import PropTypes from 'prop-types';
import React from 'react';

import { captionStyles, col, colTwo, imageStyle, quoteStyle, root, sourceStyle } from './index.module.scss';
import buildImageObj from '../../utils/build-image-obj';
import imageUrlFor from '../../utils/image-url-for';

const QuoteImage = (props) => {
  const { image, quote, source } = props;

  return (
    <section className={root}>
      <div className={col}>
        <span className={quoteStyle}>&ldquo;{quote}&rdquo;</span>
        <sub className={sourceStyle}>&mdash; {source}</sub>
      </div>
      <div className={colTwo}>
        {image && image.asset && (
          <img
            className={imageStyle}
            src={imageUrlFor(buildImageObj(image)).width(898).height(632).fit('crop').auto('format').quality(80).url()}
            alt={image.alt}
          />
        )}
        {image && image.caption && <div className={captionStyles}>{image.caption}</div>}
      </div>
    </section>
  );
};

QuoteImage.propTypes = {
  image: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({}),
    caption: PropTypes.string,
  }).isRequired,
  quote: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};

export default QuoteImage;
