import PropTypes from 'prop-types';
import React from 'react';

import { captionStyles, col, colTwo, imageStyles, root } from './index.module.scss';
import BlockContent from '../block-content';
import buildImageObj from '../../utils/build-image-obj';
import imageUrlFor from '../../utils/image-url-for';

const ImageWithCopy = (props) => {
  const { copy, image, orientation } = props;

  return orientation === 'leftImageWithCopy' ? (
    <section className={root}>
      <div className={colTwo}>
        {image && image.asset && (
          <img
            className={imageStyles}
            src={imageUrlFor(buildImageObj(image)).width(898).height(632).fit('crop').auto('format').quality(80).url()}
            alt={image.alt}
          />
        )}
        {image && image.caption && <div className={captionStyles}>{image.caption}</div>}
      </div>
      <div className={col}>{copy && <BlockContent blocks={copy} />}</div>
    </section>
  ) : (
    <section className={root}>
      <div className={col}>{copy && <BlockContent blocks={copy} />}</div>
      <div className={colTwo}>
        {image && image.asset && (
          <>
            <img
              className={imageStyles}
              src={imageUrlFor(buildImageObj(image)).width(898).height(632).fit('crop').auto('format').url()}
              alt={image.alt}
            />
            {image && image.caption && <div className={captionStyles}>{image.caption}</div>}
          </>
        )}
      </div>
    </section>
  );
};

ImageWithCopy.propTypes = {
  copy: PropTypes.arrayOf(PropTypes.shape({})),
  image: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({}),
    caption: PropTypes.string,
  }).isRequired,
  orientation: PropTypes.string.isRequired,
};

ImageWithCopy.defaultProps = {
  copy: null,
};

export default ImageWithCopy;
