import PropTypes from 'prop-types';
import React from 'react';

import { col, copyStyle, root, row, titleStyle } from './index.module.scss';
import BlockContent from '../block-content';

const TwoColumnNotes = (props) => {
  const {
    titleOne,
    copyOne,
    titleTwo,
    copyTwo,
    titleThree,
    copyThree,
    titleFour,
    copyFour,
    titleFive,
    copyFive,
    titleSix,
    copySix,
  } = props;

  return (
    <section className={root}>
      <div className={row}>
        <div className={col}>
          <sub className={titleStyle}>{titleOne}</sub>
          <sub className={copyStyle}>{copyOne && <BlockContent blocks={copyOne} />}</sub>
        </div>
        <div className={col}>
          <sub className={titleStyle}>{titleTwo}</sub>
          <sub className={copyStyle}>{copyTwo && <BlockContent blocks={copyTwo} />}</sub>
        </div>
        <div className={col} />
      </div>
      <div className={row}>
        <div className={col}>
          <sub className={titleStyle}>{titleThree}</sub>
          <sub className={copyStyle}>{copyThree && <BlockContent blocks={copyThree} />}</sub>
        </div>
        <div className={col}>
          <sub className={titleStyle}>{titleFour}</sub>
          <sub className={copyStyle}>{copyFour && <BlockContent blocks={copyFour} />}</sub>
        </div>
        <div className={col} />
      </div>
      <div className={row}>
        <div className={col}>
          <sub className={titleStyle}>{titleFive}</sub>
          <sub className={copyStyle}>{copyFive && <BlockContent blocks={copyFive} />}</sub>
        </div>
        <div className={col}>
          <sub className={titleStyle}>{titleSix}</sub>
          <sub className={copyStyle}>{copySix && <BlockContent blocks={copySix} />}</sub>
        </div>
        <div className={col} />
      </div>
    </section>
  );
};

TwoColumnNotes.propTypes = {
  copyFive: PropTypes.arrayOf(PropTypes.shape({})),
  copyFour: PropTypes.arrayOf(PropTypes.shape({})),
  copyOne: PropTypes.arrayOf(PropTypes.shape({})),
  copySix: PropTypes.arrayOf(PropTypes.shape({})),
  copyThree: PropTypes.arrayOf(PropTypes.shape({})),
  copyTwo: PropTypes.arrayOf(PropTypes.shape({})),
  titleFive: PropTypes.string,
  titleFour: PropTypes.string,
  titleOne: PropTypes.string,
  titleSix: PropTypes.string,
  titleThree: PropTypes.string,
  titleTwo: PropTypes.string,
};

TwoColumnNotes.defaultProps = {
  copyFive: [],
  copyFour: [],
  copyOne: [],
  copySix: [],
  copyThree: [],
  copyTwo: [],
  titleFive: '',
  titleFour: '',
  titleOne: '',
  titleSix: '',
  titleThree: '',
  titleTwo: '',
};

export default TwoColumnNotes;
