import PropTypes from 'prop-types';
import React from 'react';

import { col, quoteStyle, root, sourceStyle } from './index.module.scss';

const Quote = (props) => {
  const { quote, source } = props;

  return (
    <section className={root}>
      <div className={col}>
        <span className={quoteStyle}>&ldquo;{quote}&rdquo;</span>
        <sub className={sourceStyle}>&mdash; {source}</sub>
      </div>
    </section>
  );
};

Quote.propTypes = {
  quote: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};

export default Quote;
