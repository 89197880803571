import PropTypes from 'prop-types'
import React from 'react'

import {
  captionStyles,
  col,
  colRight,
  colInner,
  colTwo,
  imageStyle,
  root
} from './index.module.scss'
import buildImageObj from '../../utils/build-image-obj'
import imageUrlFor from '../../utils/image-url-for'

const Image = ({ image }) =>
  image &&
  image.asset && (
    <img
      className={imageStyle}
      src={imageUrlFor(buildImageObj(image))
        .width(431)
        .height(298)
        .fit('crop')
        .auto('format')
        .quality(80)
        .url()}
      alt={image.alt}
    />
  )

const imagePropType = {
  alt: PropTypes.string,
  asset: PropTypes.shape({}),
  caption: PropTypes.string
}

Image.propTypes = {
  image: PropTypes.shape(imagePropType).isRequired
}

const ThreeImage = props => {
  const { imageOne, imageTwo, imageThree, orientation } = props

  return orientation === 'leftLargeImage' ? (
    <section className={root}>
      <div className={colTwo}>
        {imageOne && imageOne.asset && (
          <img
            className={imageStyle}
            src={imageUrlFor(buildImageObj(imageOne))
              .width(898)
              .height(632)
              .fit('crop')
              .auto('format')
              .quality(80)
              .url()}
            alt={imageOne.alt}
          />
        )}
        {imageOne && imageOne.caption && (
          <div className={captionStyles}>{imageOne.caption}</div>
        )}
      </div>
      <div className={col}>
        <div className={colInner}>
          <Image image={imageTwo} />
          {imageTwo && imageTwo.caption && (
            <div className={captionStyles}>{imageTwo.caption}</div>
          )}
        </div>
        <div className={colInner}>
          <Image image={imageThree} />
          {imageThree && imageThree.caption && (
            <div className={captionStyles}>{imageThree.caption}</div>
          )}
        </div>
      </div>
    </section>
  ) : (
    <section className={root}>
      <div className={colRight}>
        <div className={colInner}>
          <Image image={imageTwo} />
          {imageTwo && imageTwo.caption && (
            <div className={captionStyles}>{imageTwo.caption}</div>
          )}
        </div>
        <div className={colInner}>
          <Image image={imageThree} />
          {imageThree && imageThree.caption && (
            <div className={captionStyles}>{imageThree.caption}</div>
          )}
        </div>
      </div>
      <div className={colTwo}>
        {imageOne && imageOne.asset && (
          <img
            className={imageStyle}
            src={imageUrlFor(buildImageObj(imageOne))
              .width(898)
              .height(632)
              .fit('crop')
              .auto('format')
              .quality(80)
              .url()}
            alt={imageOne.alt}
          />
        )}
        {imageOne && imageOne.caption && (
          <div className={captionStyles}>{imageOne.caption}</div>
        )}
      </div>
    </section>
  )
}

ThreeImage.propTypes = {
  imageOne: PropTypes.shape(imagePropType).isRequired,
  imageThree: PropTypes.shape(imagePropType).isRequired,
  imageTwo: PropTypes.shape(imagePropType).isRequired
}

export default ThreeImage
