import BaseBlockContent from '@sanity/block-content-to-react';
import PropTypes from 'prop-types';
import React from 'react';

import clientConfig from '../../../client-config';
import serializers from '../serializers';

const BlockContent = ({ blocks }) => (
  <BaseBlockContent blocks={blocks} serializers={serializers} {...clientConfig.sanity} />
);

BlockContent.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BlockContent;
