import PropTypes from 'prop-types';
import React from 'react';

import { captionStyles, col, colInner, imageStyles, root } from './index.module.scss';
import buildImageObj from '../../utils/build-image-obj';
import imageUrlFor from '../../utils/image-url-for';

const Image = ({ image }) =>
  image &&
  image.asset && (
    <img
      className={imageStyles}
      src={imageUrlFor(buildImageObj(image)).width(431).height(298).fit('crop').auto('format').quality(80).url()}
      alt={image.alt}
    />
  );

const imagePropType = {
  alt: PropTypes.string,
  asset: PropTypes.shape({}),
  caption: PropTypes.string,
};

Image.propTypes = {
  image: PropTypes.shape(imagePropType).isRequired,
};

const SixImage = (props) => {
  const { imageOne, imageTwo, imageThree, imageFour, imageFive, imageSix } = props;

  return (
    <section className={root}>
      <div className={col}>
        <div className={colInner}>
          <Image image={imageOne} />
          {imageOne && imageOne.caption && <div className={captionStyles}>{imageOne.caption}</div>}
        </div>
        <div className={colInner}>
          <Image image={imageFour} />
          {imageFour && imageFour.caption && <div className={captionStyles}>{imageFour.caption}</div>}
        </div>
      </div>
      <div className={col}>
        <div className={colInner}>
          <Image image={imageTwo} />
          {imageTwo && imageTwo.caption && <div className={captionStyles}>{imageTwo.caption}</div>}
        </div>
        <div className={colInner}>
          <Image image={imageFive} />
          {imageFive && imageFive.caption && <div className={captionStyles}>{imageFive.caption}</div>}
        </div>
      </div>
      <div className={col}>
        <div className={colInner}>
          <Image image={imageThree} />
          {imageThree && imageThree.caption && <div className={captionStyles}>{imageThree.caption}</div>}
        </div>
        <div className={colInner}>
          <Image image={imageSix} />
          {imageSix && imageSix.caption && <div className={captionStyles}>{imageSix.caption}</div>}
        </div>
      </div>
    </section>
  );
};

SixImage.propTypes = {
  imageFive: PropTypes.shape(imagePropType).isRequired,
  imageFour: PropTypes.shape(imagePropType).isRequired,
  imageOne: PropTypes.shape(imagePropType).isRequired,
  imageSix: PropTypes.shape(imagePropType).isRequired,
  imageThree: PropTypes.shape(imagePropType).isRequired,
  imageTwo: PropTypes.shape(imagePropType).isRequired,
};

export default SixImage;
